import React from 'react';
import { Link } from 'gatsby';
// import logo from '../img/logo.svg';

const Navbar = class extends React.Component {

  componentDidMount() {
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

      // Add a click event on each of them
      $navbarBurgers.forEach(el => {
        el.addEventListener('click', () => {

          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');

        });
      });
    }
  }

  render() {
    return (

      <nav className="navbar is-transparent" role="navigation" aria-label="main-navigation">
        <div className="container">
          <div className="navbar-brand">
            {/* Site Logo */}
            <Link to="/" className="navbar-item" title="Logo">
              <span>danny allegrezza</span>
            </Link>
            {/* Hamburger menu */}
            <div className="navbar-burger burger" data-target="navMenu">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div id="navMenu" className="navbar-menu">
            {this.renderNavbar()}

          </div>
        </div>
      </nav>
    )
  }

  renderNavbar() {
    return (
      <div className="navbar-end has-text-centered is-right">
        <Link className="navbar-item" to="/about">ABOUT</Link>
        <Link className="navbar-item" to="/blog">BLOG</Link>
        {/* <Link className="navbar-item" to="/products">Products</Link> */}
        <Link className="navbar-item" to="/cars">CARS</Link>
        <Link className="navbar-item" to="/contact">CONTACT</Link>
        {/* <Link className="navbar-item" to="/contact/examples">Form Examples</Link> */}
      </div>
    )
  }
}

export default Navbar
